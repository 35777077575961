import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';


import mainBg from "../../assets/img/main_bg.png";
import bayerLogo from '../../assets/img/bayer_logo.png';
import uniendoLogo from '../../assets/img/uniendo.png';

import logoCv from '../../assets/video/logoCV_bucle.mp4';

const MainPage = (props) => {

    const navigate = useNavigate();

    const onClickStart = () => {
        const blockOnTopInfo = document.querySelector('.blockOnTopInfo');
        const blockOnTopLogo = document.querySelector('.blockOnTopLogo');

        const bayerLogo = document.querySelector('.bayerLogo')

        const uniendoLogo = document.querySelector('.uniendoLogo');

        blockOnTopInfo.style.display = 'none';
        blockOnTopLogo.style.display = 'flex';

        bayerLogo.style.display = 'none';

        // uniendoLogo.classList.add('animate');

        setTimeout(() => {
            navigate('/wishes');
        }, 7000);
    }

    return <>
        <div className="mainBg" style={{backgroundImage: `url(${mainBg})`, width: '100vw', height: '100vh',  maxWidth: '100vw', maxHeight: '100vh', backgroundSize: 'cover', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', padding: '2.5rem'}}>
            <div className="blockOnTopInfo" style={{height: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                <h1 style={{textAlign: 'center', color: '#4E2D7C', fontFamily: 'Effra, sans-serif', fontSize: '5rem', fontWeight: '700'}}>
                    Si pudieras pedir un<br/>
                    deseo para tus pacientes,<br/>
                    ¿Qué pedirías?
                </h1>
                <h3 style={{textAlign: 'center', color: '#4E2D7C', fontFamily: 'Effra, sans-serif', fontSize: '2.125rem', fontWeight: '300'}}>
                    #sumemosdeseos juntos
                </h3>
                <div className="mainButton" onClick={onClickStart}>
                    Empezar
                </div>
            </div>
            <div class="blockOnTopLogo" style={{height: '100%', display: 'none', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center'}}>
                <video autoPlay muted style={{maxWidth: '100vw', width: '100vw'}}>
                    <source src={logoCv} type="video/mp4" />
                </video>
                {/*<img className='uniendoLogo' src={uniendoLogo} style={{width: '100%', maxWidth: '40vw'}}/>*/}
            </div>
            <img className="bayerLogo" src={bayerLogo} style={{maxWidth: '4.375rem'}}/>
        </div>
    </>
}

export default MainPage;