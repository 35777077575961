import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import layout from "simple-keyboard-layouts/build/layouts/spanish";

import mainBg from "../../assets/img/main_bg.png";
import bayerLogo from '../../assets/img/bayer_logo.png';
import verquvoLogo from '../../assets/img/verquvo_logo.png';
import xareltoLogo from '../../assets/img/xarelto_logo.png';

import nube001 from "../../assets/img/nubes/001.png";
import nube002 from "../../assets/img/nubes/002.png";
import nube003 from "../../assets/img/nubes/003.png";
import nube004 from "../../assets/img/nubes/004.png";
import nube005 from "../../assets/img/nubes/005.png";
import nube000 from "../../assets/img/nubes/000.png";

import adminIcon from "../../assets/img/icon_admin.svg";
import heartIcon from "../../assets/img/heart.svg";

import bayerReel from '../../assets/video/Bayer_reel.mp4';

import CsvDownloadButton from 'react-json-to-csv'


const WishesPage = (props) => {

    const apiUrl = 'https://bayerdeseosapi.needpluto.io';

    const navigate = useNavigate();

    const onClickBayer = () => {
        navigate('/');
    }

    const [layoutName, setLayoutName] = useState("default");
    const [inputValue, setInputValue] = useState("");
    const [shiftLast, setShiftLast] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [wishes, setWishes] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCode, setAdminCode] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    const localTest = false;

    const keyboard = useRef();

    const loadWishes = async () => {
        if(localTest) return;
        try {
            const response = await fetch(apiUrl+'/records');
            const result = await response.json();
            setWishes(result);
        } catch (error) {
            console.error(error);
        }
    }

    const postWish = async (wish) => {
        if(localTest) {
            const tempWishes = [...wishes];
            tempWishes.push({value: wish, likes: 0});
            setWishes(tempWishes);
            console.log(wishes);
            return;
        }
        const query = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
            },
            method: 'POST',
            body: JSON.stringify({value: wish})
        }

        try {
            const response = await fetch(apiUrl+'/record', query);
        } catch (error) {
            console.error(error);
        }

    }

    const deleteWish = async (wishId) => {
        if(localTest) {
            const tempWishes = wishes.map(wish => wish.id != wishId);
            setWishes(tempWishes);
            return;
        }
        const query = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
            },
            method: 'DELETE',
        }

        try {
            const response = await fetch(`${apiUrl}/record/${wishId}`, query);
        } catch (error) {
            console.error(error);
        }
    }

    const addLike = async (wishId, currentLikes) => {
        if(localTest) {
            const tempWishes = wishes.map(wish => {
                if(wish.id === wishId) {
                    wish.likes = currentLikes;
                }
                return wish;
            });
            setWishes(tempWishes);
            return;
        }

        const query = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
            },
            method: 'PUT',
            body: JSON.stringify({likes: currentLikes})
        }

        try {
            const response = await fetch(`${apiUrl}/record/${wishId}`, query);
        } catch (error) {
            console.error(error);
        }
    }

    const onChange = input => {
        setInputValue(input);
        console.log(inputValue);
    }

    const onKeyPress = button => {

        if(button === "{shift}" || button === "{lock}") {
            handleShift();
            if(button === "{shift}") {
                setShiftLast(true);
            }
        } else {
            if(shiftLast) {
                handleShift();
                setShiftLast(false);
            }
        }
    }

    const handleShift = () => {
        setLayoutName( layoutName === "default" ? "shift" : "default");
    }

    const nubes = [
        nube001,
        nube002,
        nube003,
        nube005
    ]

    useEffect(() => {
    }, [wishes]);

    useEffect(() => {
        loadWishes();
        setTimeout(() => {
            navigate('/');
        }, 1000 * 60 * 5);
    }, [])

    return <>
        <div className="wishesBg" style={{background: 'linear-gradient(180deg, #D75D65 0%, #54368A 100%)', width: '100vw', height: '100vh',  maxWidth: '100vw', maxHeight: '100vh', backgroundSize: 'cover', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center'}}>
            <div className="wishesFeed" style={{width: '100vw', height: '100vh', maxWidth: '100vw', maxHeight: '100vh', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', overflowY: 'scroll', paddingBottom: '200px', paddingTop: '50px'}}>
                {
                    adminCode ? <>
                        <div className="adminOpen" style={{display: 'flex', flexDirection: 'column', position: 'fixed', top: '0', left: '0', width: '100%', height: '100vh', backgroundColor:'rgba(0, 0, 0, 0.6)', zIndex: '99', alignItems: 'center', justifyContent: 'center'}}>
                            <div className="adminKeyContainer" style={{width: "400px"}}>
                                <Keyboard
                                    keyboardRef={r => (keyboard.current = r)}
                                    layout={{
                                        default: ["1 2 3", "4 5 6", "7 8 9", " 0 "],
                                        shift: ["1 2 3", "4 5 6", "7 8 9", " 0 "]
                                    }}
                                    layoutName="default"
                                    onChange={(input) => {
                                        console.log(input);
                                        if(input.length >= 4) {
                                            if(input == "2222") {
                                                setAdminCode(false);
                                                setIsAdmin(true);
                                            } else {
                                                setIsAdmin(false);
                                                setAdminCode(false);
                                                setAdminCode(true);
                                                keyboard.current.clearInput();
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </> : <></>
                }                
                {
                    showKeyboard ? <>
                        <div className="createWish" style={{display: 'flex', flexDirection: 'column', position: 'fixed', top: '0', left: '0', width: '100%', height: '100vh', backgroundColor:'rgba(0, 0, 0, 0.6)', zIndex: '99', alignItems: 'center'}}>
                            <div className="createWishCont" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80vw', height: '100%', justifyContent: 'center', gap: '50px'}}>
                                <div className="newWish wish1" style={{backgroundImage: `url(${nube000})`, width: '100%', maxWidth: '70vw', height: 'auto', minHeight: '40vh', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', padding: '100px 0px', flexDirection: 'column', gap: '30px', marginTop: '30px', position: 'relative'}}>
                                    <p style={{textAlign: 'center', color: '#4E2D7C', fontFamily: 'Effra, sans-serif', fontSize: '1.5rem', width: '100%', maxWidth: '40vw'}}>{inputValue}</p>
                                    <p style={{fontSize: '0.8rem'}}>{inputValue.length}/150</p>
                                </div>
                                <Keyboard
                                    layout={layout.layout}
                                    layoutName={layoutName || "default"}
                                    onChange={onChange}
                                    onKeyPress={onKeyPress}
                                    maxLength={150}
                                />
                                <div className="mainButton" onClick={async () => {
                                    const newWish = document.querySelector('.newWish');
                                    newWish.classList.add('slide-up');
                                    await postWish(inputValue);
                                    await setInputValue('');
                                    await loadWishes();
                                    setTimeout(() => {
                                        setShowKeyboard(false);
                                    }, 600);
                                    setTimeout(() => {
                                        setShowVideo(true);
                                    }, 3000);
                                    setTimeout(() => {
                                        const skipVideo = document.querySelector('.saltarVideo');
                                        skipVideo.style.display = 'block';
                                    }, 20000);
                                }}>
                                    Enviar deseo
                                </div>
                            </div>
                        </div>
                    </> : <></>
                }
                
                {
                    wishes.toReversed().map((wish, i) => {
                        const num1 = Math.floor((Math.random() * 4) + 1);
                        const num2 = Math.floor((Math.random() * 3) + 1);
                        const maxWidth = Math.floor((Math.random() * (45 - 30)) + 30);

                        return <>
                            <div className={`wishContainer${i == 0 ? ' isNew' : ''}`} style={{display: 'flex', flexDirection: 'column', padding: '30px', width: '100%', maxWidth: `${maxWidth}vw`, justifyContent: 'center', height: 'auto', minHeight: '30vh'}} key={wish.id}>                                
                                <div className={`wish${num1}`} style={{backgroundImage: `url(${nubes[num2]})`, width: '100%', height: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', padding: '100px 0px', flexDirection: 'column', gap: '10px'}}>
                                    <p style={{maxWidth: '50%', textAlign: 'center', color: 'white', fontFamily: 'Effra, sans-serif', fontSize: '1rem'}}>{wish.value}</p>
                                    {
                                        isAdmin ? <div id={wish.id} style={{padding: '5px 10px', display: 'flex', backgroundColor: 'red', color: 'white', borderRadius: '50%', fontSize: '1.5rem'}} onClick={async () => {
                                            const resp = window.confirm('¿Desea eliminar este deseo?');
                                            if(resp) {
                                                await deleteWish(wish.id);
                                            }
                                            await loadWishes();
                                        }}>X</div> : <></>
                                    }
                                    <div className="wishLikes" style={{display: "flex", alignItems: 'center', justifyContent: 'center', gap: '10px', borderRadius: '9px', border: '2px solid #4E2D7C', padding: '5px'}} onClick={ async () => {
                                        const likes = wish.likes ? Number(wish.likes) + 1 : 1;
                                        await addLike(wish.id, likes);
                                        await loadWishes();
                                    }}>
                                        <img src={heartIcon} style={{width: '1.5rem'}} /> <p style={{color: 'white'}}>{wish.likes ?? 0}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    })
                }
                
                {/*<input value={inputValue}/>
                <Keyboard
                    layout={layout.layout}
                    layoutName={layoutName || "default"}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                />*/}
            </div>
            <div className="wishesFooter" style={{backgroundColor: '#E1E1E1', width: '100vw', height: '15vh', maxWidth: '100vw', maxHeight: '15vh', display: 'flex', alignItems: 'center', padding: '0 3.5rem', position: 'fixed', bottom: '0'}}>
                <div className="leftFooter" style={{width: '35%', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '2rem'}}>
                    <h3 style={{textAlign: 'center', color: '#4E2D7C', fontFamily: 'Effra, sans-serif', fontSize: '3rem', fontWeight: '700'}}>
                        Dale like al mejor deseo
                    </h3>
                </div>
                <div className="centerFooter" style={{width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '20px'}}>
                    <div className="mainButton" style={{marginTop: '-10vh'}} onClick={() => setShowKeyboard(true)}>
                        Escribe tu deseo +
                    </div>
                    <p style={{textAlign: 'center', color: '#4E2D7C', fontFamily: 'Effra, sans-serif', fontSize: '2rem', fontWeight: '700'}}>Ya sumamos {wishes.length} deseos</p>
                </div>
                <div className="rightFooter" style={{width: '35%', display: 'flex', alignItems: 'end', justifyContent: 'end', gap: '10px', flexDirection:'column'}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '2rem'}}>
                        <img src={bayerLogo} style={{maxWidth: '7vh'}} onClick={onClickBayer}/>
                        <img src={verquvoLogo} style={{maxHeight: '5vh'}}/>
                        <img src={xareltoLogo} style={{maxHeight: '5vh'}}/>
                    </div>
                    <p style={{fontSize: '0.5rem'}}>PP-XAR-ES-2424-1 10/23</p>
                </div>
            </div>
            <div className='adminMode' style={{width: '60px', height: '60px', borderRadius: '100%', zIndex: '9999', position: 'fixed', top: '3.5rem', right: '3.5rem', padding: '10px', backgroundColor: '#4E2D7C'}} onClick={() => {
                    if(isAdmin) setIsAdmin(false);
                    else setAdminCode(!adminCode)
                }}>
                <img src={adminIcon}/>
            </div>
            {
                isAdmin ? <>
                    <div className="exportData" style={{position: 'fixed', top: '3.5rem', left:'3.5rem'}}>
                        <CsvDownloadButton data={wishes} />
                    </div>
                </> : <></>
            }
            {
                showVideo ? <>
                    <div className="videoReel" style={{backgroundColor: 'black', width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', position:'fixed', zIndex: '999999'}}>
                        <video autoPlay muted style={{height: '100vh'}} onEnded={() => setShowVideo(false)}>
                            <source src={bayerReel} type="video/mp4" />
                        </video>
                        <div className="mainButton saltarVideo" style={{position: 'absolute', bottom: '3.5rem', right: '3.5rem', display: 'none'}} onClick={() => setShowVideo(false)}>
                            Saltar video
                        </div>                            
                    </div>
                </> : <></>
            }
        </div>
    </>
}

export default WishesPage;