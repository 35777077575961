import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import MainPage from './pages/MainPage';
import WishesPage from './pages/WishesPage';

function App() {
  return (
  <Router>
    <Routes>
      <Route path="/" element={<MainPage />}/>
      <Route path="/wishes" element={<WishesPage />}/>
    </Routes>
  </Router>
  );
}

export default App;
